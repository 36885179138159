/* eslint-disable class-methods-use-this */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  /**
   * @description Returns the `content` of the `meta` tag found in `head`
   * @param {string} name
   * @returns {string | null}
   */
  metaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`);
    return element && element.getAttribute('content');
  }

  /**
   * @description Find out if the page is still in preview mode. This happens when a user transitions from one page
   * to another via turbolinks
   * @returns {boolean}
   */
  get isTurbolinksPreview() {
    return document.documentElement.hasAttribute('data-turbolinks-preview');
  }

  /**
   * @description Get current user's tzinfo
   */
  get tzinfo() {
    return this.metaValue('current-user-tzinfo') || Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  /**
   * @description Get current_user's current_org id
   * @returns {string}
   */
  get currentOrgId() {
    return this.metaValue('current-org-id') || '';
  }
}
