import * as qs from 'qs';

/**
 * @description Construct a URL with query params
 * @example
 * constructURL('/about', { user_id: 1 }).toString();
 * // => '/about?user_id=1'
 *
 * constructURL(window.location.href, { user_ids: [1, 2] }).toString();
 * // => '/pricing?user_ids[]=1&user_ids[]=2' // Rails `params` friendly
 */
export function constructURL(string: string, params: { [key: string]: string | string[] | null }) {
  const { url, searchParams } = getURLDetails(string);
  url.search = qs.stringify(
    { ...qs.parse(searchParams.toString()), ...params },
    { arrayFormat: 'brackets', skipNulls: true }
  );

  return url;
}

/**
 * @description Compare between two URLs
 */
export function isEqual(a: string, b: string) {
  const { url: urlA, searchParams: searchParamsA } = getURLDetails(a);
  const { url: urlB, searchParams: searchParamsB } = getURLDetails(b);

  if (urlA.origin !== urlB.origin || urlA.pathname !== urlB.pathname) return false;
  if (searchParamsA.toString() !== searchParamsB.toString()) return false;
  return true;
}

/**
 * @description Get normalized `url` and `searchParams` from a URL or a string
 */
export function getURLDetails(url: string | URL) {
  const baseURL = new URL(url, window.location.href);
  const searchParams = new URLSearchParams(baseURL.search);
  searchParams.sort();

  return { url: baseURL, searchParams };
}
