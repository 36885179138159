import { onDropdownClose } from 'helpers/selectize_helpers';
import axios from 'axios';

export default class DiagnosisCodes {
  constructor() {
    this.diagnosisCodeAlertMap = [];
    this.registerExistingSelectComponents();
  }

  static domControl = [
    {
      regex: /F84.0/i,
      childId: 'autism-alert',
      alertType: 'autism-alert',
    },
    {
      regex: /F80.9|H91.90|H90.5|R63.30/i,
      childId: 'non-specific-alert',
      alertType: 'non-specific-alert',
    },
    {
      regex: /Medical/i,
      childId: 'medical-code-present',
      alertType: 'medical-code-present',
    },
    {
      regex: /Treatment/i,
      childId: 'treatment-code-present',
      alertType: 'treatment-code-present',
    },
    {
      regex: /^F\d+(\.\d+)?/i,
      childId: 'medical-code-violation',
      alertType: 'medical-code-violation',
    },
    {
      regex: /^R\d+(\.\d+)?/i,
      childId: 'medical-code-violation',
      alertType: 'medical-code-violation',
    },
  ];

  /**
   * Finds all instances of diagnosis code select components and performs
   * related functions.
   * @function registerExistingSelectComponents
   */
  registerExistingSelectComponents() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const classThis = this;

    $('.select.diagnosis-code-select').each((index, select) => {
      if (!$(select).hasClass('selectized')) {
        classThis.addNewDiagnosisSelectComponent(select);
      }
    });

    const existingAutocompleteElements = document.querySelectorAll('awc-autocomplete');
    existingAutocompleteElements.forEach((awc) => {
      setTimeout(() => {
        this.controlLoop(awc.input.value, awc).handleRemove(awc, false).checkCodeCombinations();
      }, 600);
    });

    document.addEventListener('awc-autocomplete:commit', (event) => {
      const parent = event.detail.target.closest('awc-autocomplete');
      this.controlLoop(event.detail.target.querySelector('span').textContent, parent)
        .handleRemove(parent, false)
        .checkCodeCombinations();
    });

    document.addEventListener('deleteClicked', (event) => {
      this.hardDelete(event.detail.alertIds).checkCodeCombinations();
    });

    this.diagnosisCodesValidation();
    this.checkCodeCombinations();

    $('#hide-forever').on('click', (e) => {
      e.preventDefault();
      try {
        axios.put('/settings', {
          user: {
            hide_autism_validation: true,
          },
        });
        $('#hide-forever-container').remove();
      } catch (error) {
        console.error(error);
      }
    });
  }

  /**
   * Instantiates selectize for our select components.
   * @function addNewDiagnosisSelectComponent
   * @param selectizeDiagnosisCode
   * @returns {*}
   */
  addNewDiagnosisSelectComponent(selectizeDiagnosisCode) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const parentScope = this;

    $(selectizeDiagnosisCode).selectize({
      valueField: 'id',
      labelField: 'name',
      searchField: 'name',
      placeholder: 'Start typing to search for a diagnosis code...',
      options: [],
      create: false,
      onDropdownClose,
      // eslint-disable-next-line consistent-return
      load(query, callback) {
        if (!query.length) return callback();
        $.ajax({
          url: '/api/v2/diagnosis-codes',
          type: 'GET',
          dataType: 'json',
          data: {
            q: query,
          },
          error() {
            callback();
          },
          success(res) {
            callback(res);
          },
        });
      },
      onChange(value) {
        parentScope.onChangeHandler(this);
      },
    });
  }

  /**
   * Handles the change event.
   * @function onChangeHandler
   * @param selectize - The instance of selectize
   */
  onChangeHandler(selectize) {
    const select = selectize.$input[0];
    const diagnosisCodeText = select.querySelector('option[selected="selected"]').textContent.toString();

    this.controlLoop(diagnosisCodeText, select).handleRemove(selectize, true).checkCodeCombinations();
  }

  controlLoop(diagnosisCodeText, selectHtmlElement) {
    DiagnosisCodes.domControl.forEach((regexTest) => {
      this.alertControl(regexTest, diagnosisCodeText, selectHtmlElement);
    });

    return this;
  }

  /**
   *
   * This function checks if the existing records violate any alerts.
   * When we load the components the onChange handler doesn't get triggered.
   * @function diagnosisCodesValidation
   * @returns null
   *
   */
  diagnosisCodesValidation() {
    const codes = document.querySelectorAll('.select.diagnosis-code-select');

    codes.forEach((selectComponent) => {
      const diagnosisCode = selectComponent.querySelector('option[selected="selected"]');

      if (diagnosisCode) {
        const diagnosisCodeText = diagnosisCode.textContent.toString();

        DiagnosisCodes.domControl.forEach((regexTest) => {
          this.alertControl(regexTest, diagnosisCodeText, selectComponent);
        });
      }
    });
  }

  /**
   * This function checks the select component code to our regexes and
   * calls any necessary functions.
   * @function alertControl
   * @param regexTest - The dom control object.
   * @param diagnosisCodeText - The text from the select component.
   * @param selectOptionInFocus - The HTML select option.
   */
  alertControl(regexTest, diagnosisCodeText, selectHtmlElement) {
    const domChild = document.querySelectorAll(`.${regexTest.childId}`);

    // If we have a match we need to show the alert and preform tasks.
    if (regexTest.regex.test(diagnosisCodeText)) {
      const code = diagnosisCodeText.match(regexTest.regex);
      const domId = selectHtmlElement.getAttribute('alert-id');

      this.toggleClassListDisplay(domChild, 'show');
      this.setAlertId(
        selectHtmlElement,
        domId || this.generateUuid(`medical-code-present-${Math.floor(Math.random() * 100).toString()}`),
        regexTest,
        code[0],
        diagnosisCodeText,
        regexTest.alertType === 'medical-code-present',
        !!domId
      );
    }
  }

  /**
   * Goes through our memory store and checks if an HTML option that
   * has our alert flag has been remove from the DOM. Performs actions based on the above.
   * @function handleRemove
   */
  handleRemove(selectize, isSelectize) {
    let domId;
    let selectedText;

    if (isSelectize) {
      selectedText = selectize.getItem(selectize.getValue()).text();
      domId = selectize.$input[0].getAttribute('alert-id');
    } else {
      domId = selectize.getAttribute('alert-id');
      if (selectize) {
        selectedText = selectize.querySelector('input[type="hidden"]').getAttribute('data-text');
      }
    }

    if (domId && selectedText) {
      const itemInMemory = this.diagnosisCodeAlertMap.findIndex((obj) => obj.domTarget === domId);
      if (itemInMemory !== -1 && this.diagnosisCodeAlertMap[itemInMemory].textContent !== selectedText) {
        this.diagnosisCodeAlertMap.splice(itemInMemory, 1);
      }
    }

    return this;
  }

  hardDelete(domIds) {
    domIds.forEach((id) => {
      const itemInMemory = this.diagnosisCodeAlertMap.findIndex((obj) => obj.domTarget === id);
      this.diagnosisCodeAlertMap.splice(itemInMemory, 1);
    });

    return this;
  }

  /**
   *
   * @function checkCodeCombinations
   */
  checkCodeCombinations() {
    const medicalAlert = document.querySelectorAll('.medical-code-present');
    const noMedicalCodeRCodeAlert = document.querySelectorAll('.no-medical-code-present');

    const medicalCode = this.diagnosisCodeAlertMap.findIndex((obj) => obj.containsMedicalCode === true);
    let fCodeWithMedical = false;
    let fCodeWithTreatment = false;
    let rCodeWithTreatment = false;
    const hasAutismAlert = this.diagnosisCodeAlertMap.findIndex((obj) => obj.code === 'F84.0');
    const hasNoSpecificAlert = this.diagnosisCodeAlertMap.filter((obj) =>
      ['F80.9', 'H91.90', 'H90.5', 'R63.30'].includes(obj.code)
    );

    this.toggleClassListDisplay(document.querySelectorAll('.autism-alert'), hasAutismAlert !== -1 ? 'show' : 'hide');
    this.toggleClassListDisplay(
      document.querySelectorAll('.non-specific-alert'),
      hasNoSpecificAlert.length > 0 ? 'show' : 'hide'
    );

    this.diagnosisCodeAlertMap.forEach((code) => {
      const focusedComponent = document.querySelector(`[alert-id="${code.domTarget}"]`);

      if (focusedComponent) {
        const parentContainer = focusedComponent.closest('.nested-fields');
        const selectItems = parentContainer.querySelectorAll('[alert-id]');
        const domIDs = [];
        selectItems.forEach((selectItem) => {
          domIDs.push(selectItem.getAttribute('alert-id'));
        });

        const controlObjects = this.diagnosisCodeAlertMap.filter((obj) => domIDs.includes(obj.domTarget));

        const treatmentCode = controlObjects.findIndex((obj) => /^Treatment/i.test(obj.code));
        const rCode = controlObjects.findIndex((obj) => /^R\d+/i.test(obj.code) && !/R48.2/i.test(obj.code));
        const fCode = controlObjects.findIndex((obj) => /^F\d+/i.test(obj.code) && !/F80.0/i.test(obj.code));
        const scopeMedicalCode = controlObjects.findIndex((obj) => obj.containsMedicalCode);

        if (scopeMedicalCode !== -1 && fCode !== -1) fCodeWithMedical = true;
        if (treatmentCode !== -1 && fCode !== -1) fCodeWithTreatment = true;
        if (treatmentCode !== -1 && rCode !== -1) rCodeWithTreatment = true;
      }
    });

    if (medicalCode !== -1) {
      this.toggleClassListDisplay(medicalAlert, !rCodeWithTreatment && fCodeWithMedical ? 'show' : 'hide');
      this.toggleClassListDisplay(noMedicalCodeRCodeAlert, 'hide');
    } else {
      this.toggleClassListDisplay(noMedicalCodeRCodeAlert, !fCodeWithTreatment ? 'show' : 'hide');
      this.toggleClassListDisplay(medicalAlert, 'hide');
    }
  }

  setAlertId(htmlElement, newIndex, regexTest, code, textContent, isMedical, update) {
    const objectIndex = this.diagnosisCodeAlertMap.findIndex((obj) => obj.domTarget === newIndex);

    if (update && objectIndex !== -1) {
      this.diagnosisCodeAlertMap[objectIndex] = {
        domTarget: newIndex,
        alertType: regexTest.alertType,
        code,
        textContent,
        containsMedicalCode: isMedical,
      };
    } else {
      const mapTemplate = {
        domTarget: newIndex,
        alertType: regexTest.alertType,
        code,
        textContent,
        containsMedicalCode: isMedical,
      };

      // Set the index we store in memory as an attribute
      htmlElement.setAttribute('alert-id', newIndex);
      // Add our new item in memory
      this.diagnosisCodeAlertMap.push(mapTemplate);
    }
  }

  generateUuid(extra) {
    const now = new Date();
    return `${
      now.getHours().toString() + now.getMinutes().toString() + now.getSeconds().toString()
    }-${extra}${Math.round(performance.now())}`;
  }

  toggleClassListDisplay(htmlElement, type) {
    if (type === 'show') {
      htmlElement.forEach((alert) => {
        alert.classList.remove('d-none');
      });
    } else {
      htmlElement.forEach((alert) => {
        if (!alert.classList.contains('d-none')) {
          alert.classList.add('d-none');
        }
      });
    }
  }

  preservePlaceholder = (placeholderSelectize) => {
    const placeholder = placeholderSelectize.$input[0].getAttribute('placeholder');
    if (placeholder) {
      placeholderSelectize.$control_input[0].placeholder = placeholder;
    }
  };
}
