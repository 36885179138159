import { ElementObserver } from '@ambiki/impulse';

/**
 * @param {number} height - The height of the wrapping element
 * @param {number} width - The width of the wrapping element
 * @param {number} x - Left offset / width of the mouse pointer
 * @param {number} y - Top offset / height of the mouse pointer
 */
// eslint-disable-next-line
export function safeRegion({ height, width, x, y }) {
  const positions = {};
  const leftOffset = x * width;
  const topOffset = y * height;

  const isPositionBottom = height - topOffset > topOffset;
  positions.bottom = isPositionBottom;
  positions.top = !isPositionBottom;

  const isPositionRight = width - leftOffset > leftOffset;
  positions.right = isPositionRight;
  positions.left = !isPositionRight;

  return positions;
}

/**
 * @param {Event} event
 */
export function cancel(event) {
  event.preventDefault();
  event.stopPropagation();
}

/**
 * @typedef {Object} Point
 * @property {number} left - The x-coordinate
 * @property {number} top - The y-coordinate
 * @param {HTMLElement} element
 * @return {Point}
 */
export function getScrollLeftTop(element) {
  let left = 0;
  let top = 0;
  const docElement = document.documentElement;
  const body = document.body || { scrollLeft: 0, scrollTop: 0 };

  // While loop checks (and then sets element to) .parentNode OR .host
  // to account for ShadowDOM. We still want to traverse up out of ShadowDOM,
  // but the .parentNode of a root ShadowDOM node will always be null, instead
  // it should be accessed through .host. See http://stackoverflow.com/a/24765528/4383938
  while (element && (element.parentNode || element.host)) {
    // Set element to element parent, or 'host' in case of ShadowDOM
    // eslint-disable-next-line no-param-reassign
    element = element.parentNode || element.host;

    if (element === document) {
      left = body.scrollLeft || docElement.scrollLeft || 0;
      top = body.scrollTop || docElement.scrollTop || 0;
    } else {
      left += element.scrollLeft || 0;
      top += element.scrollTop || 0;
    }

    if (element.nodeType === 1 && element.style.position === 'fixed') break;
  }

  return {
    left,
    top,
  };
}

/**
 * @typedef {Object} Point
 * @property {number} left - The x-coordinate
 * @property {number} top - The y-coordinate
 * @param {HTMLElement} element
 * @return {Point}
 */
export function getElementOffset(element) {
  const doc = element && element.ownerDocument;
  let box = { left: 0, top: 0 };
  const offset = { left: 0, top: 0 };
  const offsetAttributes = {
    borderLeftWidth: 'left',
    borderTopWidth: 'top',
    paddingLeft: 'left',
    paddingTop: 'top',
  };

  if (!doc) return offset;

  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const attr in offsetAttributes) {
    offset[offsetAttributes[attr]] += parseInt(getElementStyle(element, attr), 10) || 0;
  }

  const docElem = doc.documentElement;
  if (typeof element.getBoundingClientRect !== 'undefined') {
    box = element.getBoundingClientRect();
  }

  const scrollLeftTop = getScrollLeftTop(element);

  return {
    left: box.left + scrollLeftTop.left - (docElem.clientLeft || 0) + offset.left,
    top: box.top + scrollLeftTop.top - (docElem.clientTop || 0) + offset.top,
  };
}

function getElementStyle(element, attr) {
  if (document.defaultView && document.defaultView.getComputedStyle) {
    const style = document.defaultView.getComputedStyle(element, null);
    return style ? style[attr] : undefined;
  }

  let value = element.style[attr];
  if (!value && element.currentStyle) {
    value = element.currentStyle[attr];
  }
  return value;
}

export function insertAfter(newNode, existingNode) {
  existingNode.parentNode.insertBefore(newNode, existingNode.nextSibling);
}

export function insertBefore(newNode, existingNode) {
  existingNode.parentNode.insertBefore(newNode, existingNode);
}

/**
 * @param {HTMLElement} element
 * @return {Boolean}
 */
export function visible(element) {
  return (
    !element.hidden &&
    !(element instanceof HTMLInputElement && element.type === 'hidden') &&
    (element.offsetWidth > 0 || element.offsetHeight > 0)
  );
}

/**
 * @param {HTMLElement} element
 * @return {Boolean}
 */
export function enabled(element) {
  return !element.disabled && element.getAttribute('aria-disabled') !== 'true';
}

/**
 * @param {HTMLElement} element
 * @return {Boolean}
 */
export function focusable(element) {
  return element.tabIndex >= 0 && !element.disabled && visible(element);
}

/**
 * @description Initializes the `offsetHeight` of the element. Generally used when the element is transitioning.
 * @param {HTMLElement} element
 */
export function reflow(element) {
  // eslint-disable-next-line no-unused-expressions
  element.offsetHeight;
}

/**
 * @param {String} selector
 * @param {Object} options
 * @param {Function} options.connected - Called when the element is connected to the DOM.
 * @param {Function} options.disconnected - Called when the element is disconnected from the DOM.
 *
 * @description Observes the DOM for elements that match the selector.
 *
 * @example
 *
 * observe('[data-toggle="tooltip"]', {
 *   // Called when the element is connected to the DOM.
 *   connected(element) {
 *     $(element).tooltip();
 *   },
 *
 *   // Called when the element is disconnected from the DOM.
 *   disconnected(element) {
 *     $(element).tooltip('dispose');
 *   },
 * });
 */
export function observe(selector, options = {}) {
  class Delegate {
    elementConnected(element) {
      const elements = this.getMatchingElements(element);
      for (const ele of elements) {
        options.connected?.(ele);
      }
    }

    elementDisconnected(element) {
      const elements = this.getMatchingElements(element);
      for (const ele of elements) {
        options.disconnected?.(ele);
      }
    }

    getMatchingElements(element) {
      const elements = Array.from(element.querySelectorAll(selector));
      if (element.hasAttribute(selector)) {
        elements.unshift(element);
      }
      return elements;
    }
  }

  const delegate = new Delegate();
  const observer = new ElementObserver(document, delegate, { attributeFilter: [selector] });
  observer.start();
}
